var tarteaucitronForceLanguage = 'fr';

var siteUrl = document.location.protocol + '//' + document.location.host;

tarteaucitron.init({
    "privacyUrl": siteUrl + "",
    "hashtag": "#cookies",
    "highPrivacy": true,
    "orientation": "overlay",
    "adblocker": false,
    "showAlertSmall": false,
    "cookieslist": true,
    "image": siteUrl + "/assets/dest/img/logo.png",
    "backgroundImage": "",
    "removeCredit": false,
    "AcceptAllCta": true,
    "DenyAllCta": true,
    "useExternalCss": true,
    "groupServices": false,
    "googleConsentMode": true
});

// tarteaucitron.user.analyticsUa = 'UA-2056568-1';
// tarteaucitron.user.analyticsAnonymizeIp = true;
// tarteaucitron.user.analyticsMore = function () { /* add here your optionnal ga.push() */ };
// (tarteaucitron.job = tarteaucitron.job || []).push('analytics');

// Google Tag Manager
// tarteaucitron.user.googletagmanagerId = 'GTM-WS7VGJ9';
// (tarteaucitron.job = tarteaucitron.job || []).push('googletagmanager');
